import axios from "axios"
import moment from "moment"

const cname = "utm_params="
const utmKeys = ["utm_source", "utm_campaign", "utm_medium", "utm_content"]

export function getUtmParams(location) {
  if (location) {
    const utmParams = location.substring(1)
    const params = Object.fromEntries(new URLSearchParams(utmParams))
    if (isUTMParams(params)) {
      document.cookie = cname + JSON.stringify(params) + "; path=/"
    }
  }
}

export function extractCookie(cname) {
  const cookieValue = document.cookie
    .split("; ")
    .find(row => row.startsWith(cname))
    ?.split("=")[1]

  if (cookieValue) {
    return JSON.parse(cookieValue)
  } else {
    return {}
  }
}

function checkACookieExists(cname) {
  return document.cookie.split(";").some(item => item.trim().startsWith(cname))
}

function isUTMParams(param) {
  const keys = Object.keys(param)
  return utmKeys.some(item => keys.includes(item))
}

function deleteUtm(cname) {
  document.cookie = cname + "; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
}

export async function submitUtm(params) {
  //params.fullname
  //params.email
  //params.mobile
  //params.project_name
  //params.projects_code
  //params.cluster_code
  //params.lead_source_name
  //params.web

  const utmParams = extractCookie(cname)

  if (utmParams?.utm_id) {
    if (utmParams?.utm_medium === "GooglePMax") {
      utmParams.campaign_ID = utmParams?.utm_id
    } else {
      utmParams.ad_id = utmParams?.utm_id
    }
    delete utmParams["utm_id"]
  }
  const kaptureData = {
    ...utmParams,
    ...params,
    website: "www.bsdcity.com",
    country_code: "ID",
    // visit_date: moment().format("YYYY-MM-DD"),
  }

  // const payload = {
  //   utm_source: "Google",
  //   utm_medium: "GooglePMax",
  //   utm_campaign: "Digital_IL_2024",
  //   utm_content: "Tresor_pmax_lead",
  //   utm_id: "12535",

  //   fullname: "Nurcholis Harmade Sauza",
  //   email: "tommy.sinaga@sinarmasland.com",
  //   mobile: "+6285845830956",
  //   project_name: "Tresor",
  //   projects_code: "SF_30CT",
  //   cluster_code: "SG_30CT_0003",
  //   lead_source_name:
  //     "DIGITAL-MARCOMM-DST-DEVELOPMENT-Tresor-20032024-BSD-WEBSITE",
  //   web: "bsdcity.com",
  //   website: "www.bsdcity.com",
  //   visit_date: "2024-10-23",
  // }

  // // kondisi ketika utm_medium = GooglePMax
  // const newPayload = {
  //   campaign_ID: payload.utm_id,
  //   utm_source: "Google",
  //   utm_medium: "GooglePMax",
  //   utm_campaign: "Digital_IL_2024",
  //   utm_content: "Tresor_pmax_lead",
  // }
  // // kondisi ketika utm_medium != GooglePMax
  // const newPayload1 = {
  //   ad_id: payload.utm_id,
  //   utm_source: "Google",
  //   utm_medium: "GooglePMax",
  //   utm_campaign: "Digital_IL_2024",
  //   utm_content: "Tresor_pmax_lead",
  // }

  // const testing = {
  //   fullname: "test",
  //   email: "tommy.sinaga@sinarmasland.com",
  //   mobile: "81282879945",
  //   project_name: "Tanakayu Chava",
  //   projects_code: "SF_30CV",
  //   cluster_code: "SG_30CV_0008",
  //   lead_source_name:
  //     "DIGITAL-MARCOMM-DST-DEVELOPMENT-TRESOR-20032024-BSD-WEBSITE",
  //   web: "bsdcity.com",
  //   utm_campaign: "Popup_IL_Home",
  //   utm_medium: "GooglePaidSearch",
  //   website: "www.bsdcity.com",
  //   visit_date: "31-10-2024",
  //   utm_id: "28374772839",
  //   utm_source: "Google",
  //   utm_content: "Digital_2024",
  //   ad_id:"tre"
  // }

  /**
   *
   * contoh payload
   * 
   * {
        "fullname": "test",
        "email": "tommy.sinaga@sinarmasland.com",
        "mobile": "81282879945",
        "project_name": "Kavling",
        "projects_code": "SF_30MA",
        "cluster_code": "SG_30MA_0003",
        "lead_source_name": "Kavling-Home-11072024-Microsite-Kavling",
        "web": "bsdcity.com",
        "utm_campaign": "Popup_IL_Home",
        "utm_medium": "GooglePaidSearch",
        "website": "www.bsdcity.com",
        "visit_date": "31-08-2024",
        "utm_id": "28374772839",
        "utm_source": "Google",
        "utm_content": "Digital_2024"
      }
   */

  /**
   * 
   * payload kapture yang lama
   * 
   * const kapture = [
    {
      title: "Website",
      ticket_details: "",
      due_date: moment().format("YYYY-MM-DD"),
      website_form: [
        {
          gad_source: "",
          utm_campaign: sap.utm_campaign,
          utm_medium: sap.utm_medium,
          mobile: sap.mobile,
          website: "www.bsdcity.com",
          project_name: sap.project_name,
          utm_id: sap.utm_id,
          gclid: "",
          fullname: sap.fullname,
          projects_code: sap.projects_code,
          lead_source_name: sap.lead_source_name,
          email: sap.email,
          cluster_code: sap.cluster_code,
          utm_source: sap.utm_source,
          utm_content: sap.utm_content,
        },
      ],
    },
  ]
   */

  const config = {
    method: "POST",
    // url:
    //   "https://smlstaging.kapturecrm.com/add-ticket-from-other-source.html/v.2.0",
    // // "https://sinarmasland.kapturecrm.com/add-ticket-from-other-source.html/v.2.0",
    // // url: process.env.CPI_URL,
    url: "https://staging.bsdcity.com/submit-utm",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Basic ZGpma3o2dmVidmo5MTN1ZHhiaDI3eTZ4dHcwdjVvMTN1dThzZ3FtaHMwc21tOXJnMjI=",
    },

    data: kaptureData,
  }

  const res = await axios(config)
  deleteUtm(cname)
  console.log(res)
}
