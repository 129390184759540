import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react"
import moment from "moment"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useLazyQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

//Components
import ArticleList from "../components/pages/news-landing/article-list"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/LangContext"

import { Card } from "../components/anti/card/card"
import { AcfCoverMain } from "../components/gutenberg/acf-cover-main"
import { Section, Container } from "../components/anti/grid/grid"
import { Link, graphql, useStaticQuery } from "gatsby"
import { getDate } from "../utils/get-date"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
  return (
    <div className="d-flex justify-content-end">
      <div className="paginate">
        <button
          aria-label="prev news"
          className="btn btn-secondary pagination-button"
          disabled={hasPreviousPage}
          onClick={prevPage}
        >
          <i className="fas fa-chevron-left" />
        </button>
        <button
          aria-label="next news"
          className="btn btn-secondary pagination-button"
          disabled={hasNextPage}
          onClick={nextPage}
        >
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    </div>
  )
}

const News = ({ pageContext, location }) => {
  const NEWS_STATIC = graphql`
    query {
      wordPress {
        categories(where: { language: EN }) {
          nodes {
            slug
            posts(first: 10000) {
              edges {
                cursor
                node {
                  id
                  title
                  date
                  slug
                  excerpt
                  featuredImage {
                    sourceUrl
                  }
                  categories {
                    nodes {
                      name
                    }
                  }
                  translation(language: ID) {
                    id
                    title
                    date
                    slug
                    excerpt
                    featuredImage {
                      sourceUrl
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
        posts(first: 10000, where: { language: EN }) {
          edges {
            cursor
            node {
              id
              title
              date
              slug
              excerpt
              featuredImage {
                sourceUrl
              }
              categories {
                nodes {
                  name
                }
              }
              translation(language: ID) {
                id
                title
                date
                slug
                excerpt
                featuredImage {
                  sourceUrl
                }
                categories {
                  nodes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const GET_NEWS_LISTS = gql`
    query AllNews($after: String, $before: String, $first: Int, $last: Int) {
      posts(
        first: $first
        last: $last
        after: $after
        before: $before
        where: { language: EN }
      ) {
        edges {
          cursor
          node {
            id
            title
            date
            slug
            excerpt
            featuredImage {
              sourceUrl
            }
            categories {
              nodes {
                name
              }
            }
            translation(language: ID) {
              id
              title
              date
              slug
              excerpt
              featuredImage {
                sourceUrl
              }
              categories {
                nodes {
                  name
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  `
  const GET_NEWS_LISTS_BY_CATEGORY = gql`
    query AllNews(
      $category: String
      $after: String
      $before: String
      $first: Int
      $last: Int
    ) {
      categories(where: { slug: [$category] }) {
        nodes {
          slug
          posts(first: $first, last: $last, after: $after, before: $before) {
            edges {
              cursor
              node {
                id
                title
                date
                slug
                excerpt
                featuredImage {
                  sourceUrl
                }
                categories {
                  nodes {
                    name
                  }
                }
                translation(language: ID) {
                  id
                  title
                  date
                  slug
                  excerpt
                  featuredImage {
                    sourceUrl
                  }
                  categories {
                    nodes {
                      name
                    }
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
          }
        }
      }
    }
  `

  const { lang, setLang } = useContext(LangContext)
  const staticData = useStaticQuery(NEWS_STATIC)

  const [offset, setOffset] = useState(1)
  const [noNews, setNoNews] = useState(false)

  const currentParam = useRef("")
  const counterPage = useRef(0)

  const [getNews, { data: newsLists, fetchMore: fetchMore }] = useLazyQuery(
    GET_NEWS_LISTS
  )
  const [
    getNewsByCategory,
    { data: newsListsByCategory, fetchMore: fetchMore2 },
  ] = useLazyQuery(GET_NEWS_LISTS_BY_CATEGORY)

  const newsPost = newsLists && newsLists.posts
  const newsPostByCategory =
    newsListsByCategory &&
    newsListsByCategory.categories &&
    newsListsByCategory.categories.nodes[0] &&
    newsListsByCategory.categories.nodes[0].posts
  const categoryName = pageContext.categoryPost

  // const wp = pageContext.data.blocks
  // const wpID = pageContext.data.translation && pageContext.data.translation.blocks
  // const allNews = pageContext.news

  const cover =
    lang == "EN"
      ? pageContext.data.blocks[0]
      : lang == "ID" && pageContext.data.translation !== null
      ? pageContext.data.translation.blocks[0]
      : lang == "ID" && pageContext.data.translation !== null
      ? pageContext.data.blocks[0]
      : pageContext.data.blocks[0]

  const slug = pageContext.slug
  const childPages = pageContext.childPages && pageContext.childPages.nodes

  const eventsUpdate = childPages.find(x => x.slug === "events-update")
  const news = childPages.find(x => x.slug === "news")
  const media = childPages.find(x => x.slug === "media")
  const pressRelease = childPages.find(x => x.slug === "press-release")
  const sustainabiltyNews = childPages.find(
    x => x.slug === "sustainability-news"
  )

  const blog = childPages.find(x => x.slug === "blog")

  const newsStaticByCategory = staticData.wordPress.categories.nodes.find(
    x => x.slug === categoryName
  )
  const allNewsStatic =
    slug === "news-events"
      ? staticData.wordPress.posts.edges
      : newsStaticByCategory.posts.edges

  // update the query with the new news results
  const updateQuery = (previousResult, { fetchMoreResult }) => {
    return slug === "news-events"
      ? fetchMoreResult.posts.edges.length
        ? fetchMoreResult
        : previousResult
      : fetchMoreResult.categories.nodes[0].posts.edges.length
      ? fetchMoreResult
      : previousResult
  }

  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  useEffect(() => {
    if (!noNews) {
      const navTabsActive =
        document.querySelector(".nav-link.active") !== null
          ? document.querySelector(".nav-link.active").getBoundingClientRect()
          : false

      const navScroll = document.querySelector(".nav-scrollable")

      const activePositionX = navTabsActive && navTabsActive.left

      navScroll.scrollTo(activePositionX - 30, 0)
    }
  }, [noNews])

  useEffect(() => {
    currentParam.current = new URLSearchParams(location?.search)
    let paramPage = parseInt(currentParam.current.get("page"))

    if (paramPage && paramPage > 1) {
      let paramPageCurrent = paramPage * 10 - 10 - 1
      const currentNewsCursor = allNewsStatic[paramPageCurrent]?.cursor

      if (currentNewsCursor) {
        setNoNews(false)
        if (slug === "news-events") {
          getNews({
            variables: {
              category: null,
              first: 10,
              last: null,
              after: currentNewsCursor,
              before: null,
            },
          })
        } else if (slug !== "news-events") {
          getNewsByCategory({
            variables: {
              category: categoryName,
              first: 10,
              last: null,
              after: currentNewsCursor,
              before: null,
            },
          })
        }
      } else {
        setNoNews(true)
      }
    } else {
      if (slug === "news-events") {
        getNews({
          variables: {
            category: null,
            first: 10,
            last: null,
            after: null,
            before: null,
          },
        })
      } else if (slug !== "news-events") {
        getNewsByCategory({
          variables: {
            category: categoryName,
            first: 10,
            last: null,
            after: null,
            before: null,
          },
        })
      }
    }
  }, [noNews])

  const newsNextPage = () => {
    const getPageNumberFromUrl = () => {
      const urlParams = new URLSearchParams(location?.search)
      const urlParamGetPage = parseInt(urlParams.get("page")) || 1
      return urlParamGetPage
    }

    const updateUrlParameter = (url, source, param, value) => {
      const urlParams = new URLSearchParams(location?.search)
      if (source) {
        urlParams.set("source", source)
      }
      urlParams.set("site", slug === "news-events" ? "all" : categoryName)
      urlParams.set(param, value)
      const newUrl = `${url.split("?")[0]}?${urlParams.toString()}`
      window.history.replaceState({ path: newUrl }, "", newUrl)
      return newUrl
    }

    let newPageNumber
    let resultParamGet = getPageNumberFromUrl()

    if (resultParamGet === 1) {
      if (counterPage.current === 0) {
        newPageNumber = getPageNumberFromUrl() + 1
      } else {
        newPageNumber = counterPage.current + getPageNumberFromUrl()
      }
    } else {
      if (counterPage.current === 0) {
        newPageNumber = getPageNumberFromUrl() + 1
      } else {
        newPageNumber = counterPage.current + 1
      }
    }

    counterPage.current = newPageNumber

    if (slug === "news-events") {
      fetchMore({
        variables: {
          category: null,
          first: 10,
          after: newsPost.pageInfo.endCursor || null,
          last: null,
          before: null,
        },
        updateQuery,
      })
    } else if (slug !== "news-events") {
      fetchMore2({
        variables: {
          category: categoryName,
          first: 10,
          after: newsPostByCategory.pageInfo.endCursor || null,
          last: null,
          before: null,
        },
        updateQuery,
      })
    }
    scrollTo("#nav-tab")
    updateUrlParameter(location?.href, "navbar", "page", newPageNumber)
  }

  const newsPrevPage = () => {
    const getPageNumberFromUrl = () => {
      const urlParams = new URLSearchParams(location?.search)
      const urlParamGetPage = parseInt(urlParams.get("page")) || 1
      return urlParamGetPage
    }

    const updateUrlParameter = (url, source, param, value) => {
      const urlParams = new URLSearchParams(location?.search)
      if (source) {
        urlParams.set("source", source)
      }
      urlParams.set("site", slug === "news-events" ? "all" : categoryName)
      urlParams.set(param, value)
      const newUrl = `${url.split("?")[0]}?${urlParams.toString()}`
      window.history.replaceState({ path: newUrl }, "", newUrl)
      return newUrl
    }

    let newPageNumber
    let resultParamGet = getPageNumberFromUrl()

    if (resultParamGet === 1) {
      if (counterPage.current === 0) {
        newPageNumber = getPageNumberFromUrl() - 1
      } else {
        newPageNumber = counterPage.current - getPageNumberFromUrl()
      }
    } else {
      if (counterPage.current === 0) {
        newPageNumber = getPageNumberFromUrl() - 1
      } else {
        newPageNumber = counterPage.current - 1
      }
    }

    counterPage.current = newPageNumber

    if (slug === "news-events") {
      fetchMore({
        variables: {
          category: null,
          first: null,
          after: null,
          last: 10,
          before: newsPost.pageInfo.startCursor || null,
        },
        updateQuery,
      })
    } else if (slug !== "news-events") {
      fetchMore2({
        variables: {
          category: categoryName,
          first: null,
          after: null,
          last: 10,
          before: newsPostByCategory.pageInfo.startCursor || null,
        },
        updateQuery,
      })
    }
    scrollTo("#nav-tab")
    updateUrlParameter(location?.href, "navbar", "page", newPageNumber)
  }

  const pathname = location.pathname.split("/news-events/")
  const isChildPage = pathname.length > 1
  const { seo } =
    pageContext.langKey === "en"
      ? pageContext.data
      : pageContext.data.translation || pageContext.data

  const SEODefaultImg =
    lang === "EN"
      ? pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl
      : pageContext?.data?.translation?.blocks[0]?.coverMain?.img
          ?.mediaItemUrl ||
        pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl

  return (
    <Layout>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={seo.opengraphImage?.sourceUrl || SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={seo.twitterImage?.sourceUrl || SEODefaultImg}
      />
      {/* <AcfCoverMain data={wp[0]} /> */}

      {noNews ? (
        <div className="not-found py-main d-flex flex-column align-items-center">
          <h1 className="mb-4 title" style={{ fontSize: "4rem" }}>
            {lang === "EN" ? "Sorry..." : "Maaf..."}
          </h1>
          <p className="mb-1 subtitle" style={{ fontSize: "1.5rem" }}>
            {lang === "EN"
              ? "Looks like you've got lost..."
              : "Sepertinya anda tersesat..."}
          </p>
          <p className="text-muted">
            {lang === "EN"
              ? "The page you're looking for doesn't exists."
              : "Halaman yang anda cari tidak dapat ditemukan."}
          </p>
          <Link
            to="/news-events/"
            className="btn btn-link font-size-lg fx-underline btn-font"
            style={{ fontWeight: "bold" }}
          >
            {lang === "EN" ? "Back to News Page" : "Kembali Ke Halaman Berita"}
          </Link>
        </div>
      ) : (
        <>
          <AcfCoverMain data={cover} />
          <Container className="pt-5">
            <div
              id="nav-tab"
              className="nav-tabs-wrapper report-wrapper news-nav-tab nav-scrollable-wrapper d-flex scrollable-fade"
            >
              <div className="nav-scrollable">
                <ul className="nav nav-tabs" id="experiment">
                  <li className="nav-item">
                    <Link
                      to={`${lang === "EN" ? "" : "/id"}/news-events/`}
                      className={`nav-link ${!isChildPage ? "active" : ""}`}
                    >
                      {lang == "EN" ? "All" : "Semua"}
                    </Link>
                  </li>
                  {childPages && (
                    <>
                      <li className="nav-item">
                        <Link
                          to={
                            eventsUpdate &&
                            `${lang === "EN" ? "" : "/id"}/news-events/${
                              eventsUpdate.slug
                            }/`
                          }
                          // className={`nav-link ${
                          //   eventsUpdate &&
                          //   isChildPage &&
                          //   pathname[1] === eventsUpdate.slug}`}

                          // to={`${lang === "EN" ? "" : "/id"}/news-events/${eventsUpdate.slug}`}
                          className={`nav-link ${
                            isChildPage && pathname[1] === eventsUpdate.slug
                              ? "active"
                              : ""
                          }`}
                        >
                          {eventsUpdate && eventsUpdate.title}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={
                            news &&
                            `${lang === "EN" ? "" : "/id"}/news-events/${
                              news.slug
                            }/`
                          }
                          className={`nav-link ${
                            news && isChildPage && pathname[1] === news.slug
                              ? "active"
                              : ""
                          }`}
                        >
                          {lang == "EN" ? news && news.title : "Berita"}
                          {}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={
                            media &&
                            `${lang === "EN" ? "" : "/id"}/news-events/${
                              media.slug
                            }/`
                          }
                          className={`nav-link ${
                            media && isChildPage && pathname[1] === media.slug
                              ? "active"
                              : ""
                          }`}
                        >
                          {media && media.title}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`${lang === "EN" ? "" : "/id"}/news-events/${
                            pressRelease.slug
                          }/`}
                          className={`nav-link ${
                            isChildPage && pathname[1] === pressRelease.slug
                              ? "active"
                              : ""
                          }`}
                        >
                          {lang == "EN" ? pressRelease.title : "Siaran Pers"}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={
                            sustainabiltyNews &&
                            `${lang === "EN" ? "" : "/id"}/news-events/${
                              sustainabiltyNews.slug
                            }/`
                          }
                          className={`nav-link ${
                            sustainabiltyNews &&
                            isChildPage &&
                            pathname[1] === sustainabiltyNews.slug
                              ? "active"
                              : ""
                          }`}
                          // className={`nav-link ${pageContext.slug == "sustainability-new" ? "active" : ""}`}
                        >
                          {lang == "EN"
                            ? sustainabiltyNews && sustainabiltyNews.title
                            : "Berita Keberlanjutan"}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={
                            blog &&
                            `${lang === "EN" ? "" : "/id"}/news-events/${
                              blog.slug
                            }/`
                          }
                          className={`nav-link ${
                            blog && isChildPage && pathname[1] === blog.slug
                              ? "active"
                              : ""
                          }`}
                        >
                          {blog && blog.title}
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </Container>
          <Section>
            <Container className="px-sm-down-0">
              {/* ARTICLE LIST START LINE ***************************** */}

              <ArticleList
                slug={slug}
                lang={lang}
                items={slug === "news-events" ? newsPost : newsPostByCategory}
                getDate={getDate}
              />

              {/* ARTICLE LIST END LINE ******************************* */}

              {slug === "news-events" && (
                <Pagination
                  hasPreviousPage={
                    newsPost && !newsPost.pageInfo.hasPreviousPage
                  }
                  hasNextPage={newsPost && !newsPost.pageInfo.hasNextPage}
                  nextPage={newsNextPage}
                  prevPage={newsPrevPage}
                />
              )}
              {slug !== "news-events" && (
                <Pagination
                  hasPreviousPage={
                    newsPostByCategory &&
                    !newsPostByCategory.pageInfo.hasPreviousPage
                  }
                  hasNextPage={
                    newsPostByCategory &&
                    !newsPostByCategory.pageInfo.hasNextPage
                  }
                  nextPage={newsNextPage}
                  prevPage={newsPrevPage}
                />
              )}
            </Container>
          </Section>
        </>
      )}
    </Layout>
  )
}

export default News
